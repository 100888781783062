<template>
    <!--v-img src='../../assets/img/imagenAnual.jpg'-->
    <v-img :src="imagen">
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-img>
</template>

<script>
  export default {
    name: 'InicioApp',

    data () {
      return {
        imagen: '/img/'+new Date().getFullYear()+'.jpg'
      }
    },
  }
</script>