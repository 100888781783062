<template>
  <v-row justify="center">
    <v-dialog
      v-model="showModal"
      persistent
      max-height="500"
    >
      <v-card class="mx-auto">
        <div>
        <v-card-title>
          <span class="text-h4">Cambiar Contraseña</span>
        </v-card-title>
        <v-card-text>
          <div class="text-subtitle-2">Favor de cambiar tu contraseña para poder ingresar</div>
          <br>
          <v-form ref="form"><!--
            @submit.prevent="login()"
          -->
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="password"
                  :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :readonly="loadSave"
                  required
                  :rules="passwordRegla"
                  :type="show ? 'text' : 'password'"
                  label="Contraseña"
                  placeholder="Introduce tu contraseña"
                  @click:append-inner="show = !show"
                  @keyup.enter="save()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="password2"
                  :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :readonly="loadSave"
                  required
                  :rules="password2Regla"
                  :type="show2 ? 'text' : 'password'"
                  label="Confirmar Contraseña"
                  placeholder="Introduce tu contraseña"
                  @click:append-inner="show2 = !show2"
                  @keyup.enter="save()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="info"
          text
          :loading="loadSave"
          @click="save()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import emitter from "../../plugins/mitt";

export default {
  name: "changePassword",

  data() {
    return {
      showModal: false,
      password: '',
      password2: '',
      show: false,
      show2: false,
      token:'',
      loadSave: false,
      passwordRegla: [
        v => !!v || 'La Contraseña es requerida.'
      ],
      password2Regla: [
        v => !!v || "Confirmar contraseña",
        v => v === this.password || "La Contraseña no coincide."
      ],
      urlApi: process.env.VUE_APP_ENV_AUTH_API
    };
  },

  mounted(){
    var self = this 

    emitter.on('changePasswordModal',function(data){
      self.showModal = data.show
      self.token = data.token
    });

  },

  methods: {    
    close(){
      emitter.emit("changePasswordModalClosed");
      this.showModal = false
      this.password = ''
      this.password2 = ''
      this.loadSave = false
    },

    async save(){
      var self = this

      const { valid } = await this.$refs.form.validate()
      if (valid){
        self.loadSave = true
        var params = {
          password: self.password
        }
        //console.log(params)
        axios.post(self.urlApi+"password", params,{
          headers: {
            Authorization: 'Bearer ' + self.token
          }
        }).then((result) => {
          self.close()
        }).catch(error=>{
          console.log(error)
          self.loadSave = false
        });
      }
    }
  }
}
</script>
