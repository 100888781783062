<template>
    <v-row justify="center">
      <v-dialog
        v-model="showModal"
        persistent
        max-height="500"
      >
        <v-card class="mx-auto">
          <v-skeleton-loader
            v-if="loading"
            type="list-item-two-line, actions"
          ></v-skeleton-loader>
          <div v-else>
          <v-card-title>
            <span class="text-h5">Alta de Credencial</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form"><!--
              @submit.prevent="login()"
            -->
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-autocomplete
                    v-model="miembro"
                    required
                    :rules="miembroRegla"
                    :items="miembros"
                    item-title="nom_completo"
                    label="Miembro"
                    item-value="id"
                    variant="underlined"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="info"
            text
            :loading="loadSave"
            @click="crearCredencial"
          >
            Dar de Alta
          </v-btn>
        </v-card-actions>
      </div>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
import axios from "axios";
import emitter from "../../plugins/mitt";

export default {
  name: "addCredencial",

  data() {
    return {
      showModal: false,
      miembros: [],
      miembro: null,
      textResponse: '',
      loadSave: false,
      loadMiembros: false,
      miembroRegla: [
        v => !!v || 'El nombre del Miembro es requerido.'
      ],
      urlApi: process.env.VUE_APP_ENV_MIEMBROS_API
    };
  },

  mounted(){
    var self = this 

    emitter.on('addCredencialModal',function(data){
      self.showModal = data.show
      
      axios.get(self.urlApi+"miembros", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        self.miembros = result.data.data
        self.loadMiembros = true
      }).catch(error=>{
        self.close()
        console.log(error)
      })
    });

  },

  computed: {
    loading () {
      if(this.loadMiembros){
        return false
      }else{
        return true
      }
    }
  },

  methods: {    
    close(){
      this.showModal = false
      this.miembro = null
      this.loadMiembros = false
      this.textResponse = ''
    },

    async crearCredencial(){
      var self = this
      
      const { valid } = await this.$refs.form.validate()
      if (valid){
        var params = {
          id_miembro: self.miembro
        }
        axios.post(self.urlApi+"credencial", params,{
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SesionID')
          }
        }).then((result) => {
          emitter.emit("addCredencialModalClosed", {success: result.data.success, response: result.data.message, data: result.data.data});
          
          self.close()
        }).catch(error=>{
          console.log(error)
          self.loadSave = false
        });
      }
    }
  }
}
</script>
