<template>
    <div>
      <v-data-table
        :loading="loading" 
        :headers="headers"
        :items="miembros"
        :search="search"
        :sort-by="[{ key: 'nom_apellidos', order: 'asc' }]"
        no-data-text="No hay credenciales vigentes para mostrar"
        v-model:page="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:loading>
          <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template>
  
        <template v-slot:top>
          <v-toolbar
            color="transparent"
          >

            <v-spacer></v-spacer>
            
            <v-text-field
                v-model="search"
                label="Buscar Miembros"
                append-inner-icon="mdi-account-search"
                single-line
                variant="underlined"
                hide-details
                :disabled="loading"
            ></v-text-field>
            
            <v-spacer></v-spacer>
            <v-tooltip 
                text="Alta de Credencial"
                location="bottom"
            >
                <template v-slot:activator="{ props }">
                    <v-btn 
                        v-bind="props"
                        icon="mdi-card-plus"
                        elevation="4"
                        color="primary"
                        variant="flat"
                        :disabled="loading"
                        @click="addCredencial()"
                    ></v-btn>
                </template>
            </v-tooltip>
            <v-dialog v-model="showConfirmaBaja" max-width="480px">
              <v-card>
                <v-card-title class="text-h6">¿Estás seguro de dar de baja esta Credencial?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-darken-1" variant="text" @click="closeDelete()">Cancelar</v-btn>
                  <v-btn color="blue-darken-1" variant="text" @click="bajaCredencial()">Si</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showConfirmaAlta" max-width="480px">
              <v-sheet
                class="pa-4 text-center mx-auto"
                elevation="12"
                max-width="600"
                rounded="lg"
                width="100%"
              >
                <v-icon
                  class="mb-5"
                  :color="showFolio ? 'success' : 'error'"
                  :icon="showFolio ? 'mdi-check-circle' : 'mdi-close-circle'"
                  size="112"
                ></v-icon>

                <h2 class="text-h5 mb-6">{{ textResponse }}</h2>

                <p class="mb-4 text-medium-emphasis text-body-2" v-if="showFolio">
                  El Folio de la credencial es: <strong>{{ folio }}</strong>
                </p>
                <br>
                <div v-if="showFolio">
                  <qrcode-vue :value="urlQR" :size="size" level="H"/>
                </div>
                <v-divider class="mb-4"></v-divider>
              
                <div class="text-end">
                  <v-btn
                    class="text-none"
                    color="success"
                    variant="outlined"
                    width="90"
                    rounded
                    @click="closeAlta()"
                  >
                    Aceptar
                  </v-btn>
                </div>
              </v-sheet>
            </v-dialog>
        </v-toolbar>
        </template>
  
        <template v-slot:item.imagen="{ item }">
          <div class="text-center">
            <v-menu
              :close-on-content-click="true"
              location="end"
            >
              <template v-slot:activator="{ props }">
                <v-row class="text-center">
                  <v-col cols="12">
                    <!--v-avatar v-bind="props" :image="require('../../assets/img/'+item.id+'.jpg')" size="45"-->
                    <v-avatar v-bind="props" :image="'/img/'+item.id+'.jpg'" size="45">
                      <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular
                            color="grey"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-avatar>
                  </v-col>
                </v-row>
              </template>
            
              <v-card 
                :title="item.nom_miembro+' '+item.nom_apellidoPaterno"
                style="overflow: hidden;"
                elevation="8"
                height="250"
                width="250"
              >        
                <!--v-img :src="require('../../assets/img/'+item.id+'.jpg')"-->
                <v-img :src="'/img/'+item.id+'.jpg'">
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                        color="grey"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </v-card>
            </v-menu>
            </div>
        </template>
  
        <template v-slot:item.actions="{ item }">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="auto">
                <v-tooltip 
                    text="Dar de Baja"
                    location="bottom"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn 
                            v-bind="props"
                            icon="mdi-card-bulleted-off-outline"
                            elevation="8"
                            color="red"
                            variant="flat"
                            size="small"
                            class="ma-1"
                            @click="showDelete(item.id_credencial)"
                        ></v-btn>
                    </template>
                </v-tooltip>
            </v-col>
          </v-row>
        </template>
        
        <template v-slot:bottom="data">
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              rounded="circle"
            ></v-pagination>
          </div>
          <slot name="bottom" :any_name="internalData = data" />
          <!--div>Pagination: {{ internalData }}</div-->
        </template>
      </v-data-table>
      <add-credencial-modal></add-credencial-modal>
    </div>
  </template>
  <script>
  import axios from "axios";
  import addCredencialModal from './AddCredencialModal.vue';
  import emitter from "../../plugins/mitt";
  import QrcodeVue from 'qrcode.vue'
  
    export default {
      name: 'CredencialesView',
  
      components: {
        addCredencialModal,
        QrcodeVue
      },
  
      data () {
        return {
          search:'',
          loadMiembros: false,
          showConfirmaBaja: false,
          showConfirmaAlta: false,
          showFolio: false,
          textResponse: '',
          folio: '',
          itemsPerPage: 10,
          size: 300,
          urlQR: '',
          page: 1,
          internalData: {},
          headers: [
              { title: '', key: 'imagen'},
              { title: 'Folio', key: 'id_credencial' },
              {
              title: 'Apellido',
              align: 'start',
              key: 'nom_apellidos',
              },
              { title: 'Nombre', key: 'nom_miembro' },
              { title: 'Ministerio', key: 'nom_ministerio' },
              { title: 'Casa de Dios', key: 'nom_congregacion' },
              { title: '', key: 'actions', sortable: false }
          ],
          miembros: [],
          idDelete: 0,
          urlApi: process.env.VUE_APP_ENV_MIEMBROS_API
        }
      }, 
  
      created() {
        var self = this
  
        self.getCredenciales()

        emitter.on('addCredencialModalClosed',function(data){
          if(data.success){
            self.textResponse = data.response
            self.folio = data.data
            self.showFolio = true
            self.urlQR = 'https://www.elvalledelasbendiciones.com/vigencia/'+data.data
            self.showConfirmaAlta = true
            self.loadMiembros = false
            //self.getCredenciales()
            //console.log(data)
          }else{
            self.textResponse = data.response
            self.showConfirmaAlta = true
          }
        });
      },
  
      computed: {
        pageCount () {
          return this.internalData.pageCount
        },
        loading () {
          if(this.loadMiembros){
              return false
          }else{
              return true
          }
        }
      },
      
      methods: {
        addCredencial() {
          var params = {
            show: true
          }
  
          emitter.emit("addCredencialModal", params);
        },

        getCredenciales(){
          var self = this
  
          axios.get(self.urlApi+"credencial", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
            self.miembros = result.data.data
            self.loadMiembros = true  
          }).catch(error=>{
            console.log(error)
          })
        },

        closeDelete(){
          this.idDelete = 0
          this.loadMiembros = false
          this.getCredenciales()
          this.showConfirmaBaja = false
        },

        showDelete(id){
          this.showConfirmaBaja = true
          this.idDelete = id
        },

        closeAlta(){
          this.showConfirmaAlta = false
          this.getCredenciales()
          this.textResponse = ''
          this.folio = ''
          this.urlQR = ''
          this.showFolio = false
        },

        bajaCredencial(){
          var self = this
          var params = {
            id_credencial: self.idDelete
          }
  
          axios.put(self.urlApi+"credencial", params,{
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('SesionID')
            }
          }).then((result) => {
            self.miembros = result.data.data
            self.closeDelete()
          }).catch(error=>{
            self.closeDelete()
            console.log(error)
          })
        }

      }
    }
  </script>
  <style>
  .v-table__wrapper{
      overflow-y: hidden;
  }
  </style>