<template>
    <div>
    <v-data-table
      :loading="loading" 
      :headers="headers"
      :items="totales"
      :search="search"
      no-data-text="No hay información para mostrar"
      v-model:page="page"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
      </template>

      <template v-slot:top>
        <v-toolbar
          color="transparent"
          style="padding-top: 12px;"
        >
          <v-autocomplete
            v-model="reporte"
            :items="reportes"
            label="Tipo Reporte"
            item-title="nom_reporte"
            variant="outlined"
            return-object
            @update:model-value="changeReporte()"
          ></v-autocomplete>
          
          <v-spacer></v-spacer>
          
          <v-text-field
              v-model="search"
              label="Buscar"
              append-inner-icon="mdi-magnify"
              single-line
              variant="underlined"
              hide-details
              :disabled="loading"
          ></v-text-field>
          
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
      </v-toolbar>
      </template>

      <template v-slot:bottom="data">
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            rounded="circle"
          ></v-pagination>
        </div>
        <slot name="bottom" :any_name="internalData = data" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

  export default {
    name: 'ReporteCongresoGralView',


    data () {
      return {
        search:'',
        loadTotales: false,
        itemsPerPage: 10,
        internalData: {},
        page: 1,
        reportes: [
            { id: 1, nom_reporte: 'Totales Pre/Registro' },
            { id: 2, nom_reporte: 'Totales Cooperación' },
            { id: 3, nom_reporte: 'Totales Platillos' }
        ],
        reporte: '',
        totales: [],
        urlApi: process.env.VUE_APP_ENV_REGISTRO_API
      }
    }, 

    created() {
      var self = this
      
      self.reporte = { id: 1, nom_reporte: 'Totales Pre/Registro' }
      self.getTotalesPreRegistro()      
    },

    computed: {
      pageCount () {
        return this.internalData.pageCount
      },
      loading () {
        if(this.loadTotales){
            return false
        }else{
            return true
        }
      },
      headers () {
        if(this.reporte.id == 1){
          return [
            { title: 'Casa de Dios', key: 'nom_congregacion' },
            { title: 'Adultos Pre', key: 'num_adultos' },
            { title: 'Menores Pre', key: 'num_menores' },
            { title: 'Total Pre-Registro', key: 'num_total' },
            { title: 'Adultos Registro', key: 'num_adultos_registro' },
            { title: 'Menores Registro', key: 'num_menores_registro' },
            { title: 'Total Registro', key: 'num_total_registro' }
          ]
        }else if(this.reporte.id == 2) {
          return [
            { title: 'Usuario', key: 'nom_usuario' },
            { title: 'Total', key: 'num_total' },
            { title: 'Fecha', key: 'fec_movimiento' }
          ]
        }else{
          return [
            { title: '', key: 'nom_edad' },
            { title: 'Total Comidas', key: 'num_totalComida' },
            { title: 'Total Cenas', key: 'num_totalCena' }
          ] 
        }
      }
    },
    
    methods: {
      changeReporte(){
        this.loadTotales = false
        
        if(this.reporte.id == 1){
            this.getTotalesPreRegistro()
        }else if(this.reporte.id == 2){
            this.getTotalesCooperacion()
        }else{
            this.getTotalesPlatillos()
        }
      },

      getTotalesPreRegistro(){
        var self = this
        var adultosPre = 0
        var menoresPre = 0
        var totalPre = 0
        var adultos = 0
        var menores = 0
        var totalRe = 0

        axios.get(self.urlApi+"reportes/preregistro", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          self.totales = result.data.data
          result.data.data.forEach(total => {
            adultosPre += total.num_adultos
            menoresPre += total.num_menores
            totalPre += total.num_total
            adultos += total.num_adultos_registro
            menores += total.num_menores_registro
            totalRe += total.num_total_registro
          });
          self.totales.push({nom_congregacion: "Totales", num_adultos: adultosPre, num_menores: menoresPre, num_total: totalPre, num_adultos_registro: adultos, num_menores_registro: menores, num_total_registro: totalRe})
          
          self.loadTotales = true

        }).catch(error=>{
          console.log(error)
        })
      },

      getTotalesCooperacion(){
        var self = this
        var total2 = 0

        axios.get(self.urlApi+"reportes/cooperacion", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          self.totales = result.data.data
          result.data.data.forEach(total => {
            total2 += total.num_total
          });
          self.totales.push({nom_usuario: "Total", num_total: total2, fec_movimiento: ''})
          
          self.loadTotales = true

        }).catch(error=>{
          console.log(error)
        })
      },

      getTotalesPlatillos(){
        var self = this

        axios.get(self.urlApi+"reportes/platillos", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          self.totales = result.data.data
          self.loadTotales = true

        }).catch(error=>{
          console.log(error)
        })
      }
    },

    
  }
</script>