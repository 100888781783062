<template>
  <v-alert
    type="error"
    :text="textResponse"
    v-if="showAlert"
  ></v-alert>
  <v-container>
    <div>
      <v-row class="text-center">
        <v-col cols="12">
          <v-img 
            :src="require('../../assets/img/logo.png')"
            max-height="350"
          >
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12">
          <v-card
            class="mx-auto"
            width="450"
            elevation="24"
          >
            <v-card-title class="text-h4 text--primary">Iniciar Sesión</v-card-title>
            <v-card-text>
              <v-form ref="form"
                @submit.prevent="login()"
              >

                <br>

                <v-text-field
                  v-model="email"
                  :readonly="loading"
                  required
                  :rules="emailRegla"
                  class="mb-2"
                  label="Correo"
                  placeholder="Introduce tu correo"
                  variant="outlined"
                  @keyup.enter="login()"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :readonly="loading"
                  required
                  :rules="passwordRegla"
                  :type="show ? 'text' : 'password'"
                  label="Contraseña"
                  placeholder="Introduce tu contraseña"
                  variant="outlined"
                  @click:append-inner="show = !show"
                  @keyup.enter="login()"
                ></v-text-field>

                <br>
                <p v-if="showIncorrecto" style="color: red;">*Correo y/o Contraseña Incorrecta</p>
                <br>

                <v-btn
                  variant="outlined"
                  block
                  rounded="xl"
                  color="green"
                  elevation="6"
                  :loading="loading"
                  @click="login()"
                >
                  Iniciar
                </v-btn>
              </v-form>
            </v-card-text>

          </v-card>
        </v-col>      
      </v-row>
      <change-password-modal></change-password-modal>
    </div>
  </v-container>
</template>

<script>
  import axios from "axios";
  import changePasswordModal from './ChangePassword.vue';
  import emitter from "../../plugins/mitt";

  export default {
    name: 'LoginView',

    components: {
      changePasswordModal,
    },

    data: () => ({
      form: false,
      email: null,
      password: null,
      loading: false,
      show: false,
      urlAuth: process.env.VUE_APP_ENV_AUTH_API,
      textResponse: '',
      showAlert: false,
      showIncorrecto: false,
      emailRegla: [
        v => !!v || 'El Correo es requerido.',
        v => (v && /^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(v) || 'Introducir un Correo válido.')
      ],
      passwordRegla: [
        v => !!v || 'La contraseña es requerida.',
        v => (v && v.length >= 6) || 'La contraseña debe contener al menos 6 caracteres.',
      ],

    }),

    created(){
      var self = this 

      emitter.on('changePasswordModalClosed',function(){
        self.loading = false
        self.password = ''
      });

    },

    methods: {      
      async login () {
        const { valid } = await this.$refs.form.validate()
        if (valid){
          this.loading = true
          
          var self = this
          var param = {
            email: self.email,
            password: self.password
          }

          axios.post(self.urlAuth+"login", param).then((result) => {
            if(result.data.data.isFirstLogin == 1){
              self.openChangePassword(result.data.data.token)
            }else{
              localStorage.setItem('SesionID', result.data.data.token)
              self.$router.push({ name: 'home' })
              self.loading = false
            }
          }).catch(error=>{
            if(error.response && error.response.status == '401'){
              self.showIncorrecto = true
              setTimeout(function(){
                  self.showIncorrecto = false;
              }, 5000);
            }else{
              self.textResponse = "Tuvimos algunos inconvenientes al iniciar sesión, comunicate con un Administrador."
              self.showAlert = true
              setTimeout(function(){
                  self.showAlert = false;
              }, 5000);
            }
            self.loading = false
            console.log(error)
          })

        }
      },
      openChangePassword(token) {
        var params = {
          show: true,
          token: token
        }

        emitter.emit("changePasswordModal", params);
      }
    },
  }
</script>
