<template>
  <div id="app" v-show="view">
    <v-app id="inspire">
      <v-app-bar color="teal" :elevation="5">
        <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>
        <v-toolbar-title>Bendiciones, {{ nombreUsuario }}</v-toolbar-title>
        <template v-slot:append>
          <v-tooltip 
            text="Salir"
            location="bottom"
          >
            <template v-slot:activator="{ props }">
              <v-btn 
                v-bind="props" 
                icon="mdi-logout"
                @click="logout()"
              ></v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app clipped hide-overlay permanent :rail="mini">
        <v-list>
          <v-list-item
            link
            @click="inicio()"
            :title="nombreUsuario"
            :subtitle="rolUsuario"
            :prepend-avatar="'/img/'+idMiembro+'.jpg'"
          >
            <!--:prepend-avatar="require('../assets/img/'+idMiembro+'.jpg')"
            -->
            <!--template v-slot:prepend>
                <v-avatar>
                  <v-img
                    :src="require('../assets/img/'+idMiembro+'.jpg')"
                  ></v-img>
                </v-avatar>
            </template-->
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense nav>
          <!--v-list-group value="Congreso">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-human-handsup"
                title="Congreso"
                v-if="idRol == 1 || idRol == 2"
              ></v-list-item>
            </template>

            <v-list-item
              link 
              @click="preRegistro()"
              title="Pre-Registro"
            ></v-list-item>
          </v-list-group>
          <v-list-item 
            link 
            @click="cooperacion()"
            prepend-icon="mdi-cash-multiple"
            title="Cooperación Pre"
            v-if="idRol == 1"
          ></v-list-item>
          <v-list-item 
            link 
            @click="cooperacionRegistro()"
            prepend-icon="mdi-cash-multiple"
            title="Cooperación"
            v-if="idRol == 1 || idRol == 4"
          ></v-list-item-->
          <v-list-item 
            link 
            @click="credenciales()"
            prepend-icon="mdi-card-account-details-outline"
            title="Credenciales"
            v-if="idRol == 1 || idRol == 2"
          ></v-list-item>
          <v-list-item 
            link 
            @click="miembros()"
            prepend-icon="mdi-account-group-outline"
            title="Miembros"
            v-if="idRol == 1"
          ></v-list-item>
          <v-list-group value="Reportes">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-ballot-outline"
                title="Reportes"
                v-if="idRol == 1"
              ></v-list-item>
            </template>

            <!--v-list-item
              link 
              @click="reporteCongresoGral()"
              title="Congreso General"
            ></v-list-item-->
          </v-list-group>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid fill-height>
          <inicio-app v-if="showInicio"></inicio-app>
          <miembros v-if="showMiembros"></miembros>
          <!--cooperacion v-if="showCooperacion" :usuario="idMiembro"></cooperacion>
          <cooperacion-registro v-if="showCooperacionRegistro" :usuario="idMiembro"></cooperacion-registro>
          <pre-registro v-if="showPreregistro" :usuario="idMiembro"></pre-registro-->
          <reporte-congreso-gral v-if="showReporteCongresoGral"></reporte-congreso-gral>
          <credenciales v-if="showCredenciales"></credenciales>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
  import axios from "axios";
  import InicioApp from '../components/inicio/InicioApp.vue'
  import Miembros from '../components/miembro/Miembro.vue'
  /*import Cooperacion from '../components/cooperacion/CooperacionGral.vue'
  import CooperacionRegistro from '../components/cooperacion/CooperacionRegistro.vue'
  import PreRegistro from '../components/preRegistro/PreRegistro.vue'*/
  import ReporteCongresoGral from '../components/reportes/ReporteCongresoGral.vue'
  import Credenciales from '../components/credenciales/Credenciales.vue'

  export default {
    name: 'HomeApp',

    data () {
      return {
        drawer: true,
        showInicio: true,
        mini: false,
        showMiembros: false,
        showCooperacion: false,
        showCooperacionRegistro: false,
        showPreregistro: false,
        showReporteCongresoGral : false,
        showCredenciales: false,
        nombreUsuario: '',
        rolUsuario: '',
        idRol: 0,
        view: false,
        idMiembro: 0,
        urlAuth: process.env.VUE_APP_ENV_AUTH_API
      }
    },
    
    components: {
      InicioApp,
      Miembros,
      /*Cooperacion,
      CooperacionRegistro,
      PreRegistro,*/
      ReporteCongresoGral,
      Credenciales
    },

    created() {
      var self = this

      axios.get(self.urlAuth+"user", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        //localStorage.setItem('SesionID', result.data.data.token)
        self.nombreUsuario = result.data.data.nombre + " " + result.data.data.apellido
        self.idMiembro = result.data.data.id_miembro
        self.idRol = result.data.data.id_rol
        self.view = true
        switch (result.data.data.id_rol) {
          case '1':
            self.rolUsuario = 'Master'
            break;
          case '2':
            self.rolUsuario = 'Administrador'
            break;
          case '4':
            self.rolUsuario = 'Registro'
            break;
        }
        
        //console.log(result)
      }).catch(error=>{
        self.$router.push({ name: 'login' })
        console.log(error)
      })
    },

    methods: {
      inicio() {
        this.showInicio = true
        this.showMiembros = false
        this.showCooperacion = false
        this.showPreregistro = false
        this.showReporteCongresoGral = false
        this.showCredenciales = false
        this.mini = true
      },

      miembros() {
        this.showMiembros = true
        this.showInicio = false
        this.showCooperacion = false
        this.showPreregistro = false
        this.showReporteCongresoGral = false
        this.showCooperacionRegistro = false
        this.showCredenciales = false
        this.mini = true
      },

      cooperacion() {
        this.showInicio = false
        this.showMiembros = false
        this.showCooperacion = true
        this.showPreregistro = false
        this.showReporteCongresoGral = false
        this.showCooperacionRegistro = false
        this.showCredenciales = false
        this.mini = true
      },

      preRegistro() {
        this.showInicio = false
        this.showMiembros = false
        this.showCooperacion = false
        this.showPreregistro = true
        this.showReporteCongresoGral = false
        this.showCooperacionRegistro = false
        this.showCredenciales = false
        this.mini = true
      },

      reporteCongresoGral() {
        this.showInicio = false
        this.showMiembros = false
        this.showCooperacion = false
        this.showPreregistro = false
        this.showReporteCongresoGral = true
        this.showCooperacionRegistro = false
        this.showCredenciales = false
        this.mini = true
      },

      cooperacionRegistro() {
        this.showInicio = false
        this.showMiembros = false
        this.showCooperacion = false
        this.showPreregistro = false
        this.showReporteCongresoGral = false
        this.showCooperacionRegistro = true
        this.showCredenciales = false
        this.mini = true
      },

      credenciales() {
        this.showMiembros = false
        this.showInicio = false
        this.showCooperacion = false
        this.showPreregistro = false
        this.showReporteCongresoGral = false
        this.showCooperacionRegistro = false
        this.showCredenciales = true
        this.mini = true
      },

      logout(){
        var self = this

        axios.get(self.urlAuth+"logout", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          localStorage.removeItem('SesionID')
          self.$router.push({ name: 'login' })
          //console.log(result)
        }).catch(error=>{
          console.log(error)
        })
      }
    
    },
  }
</script>
